<template>
  <v-container>
      <div class="my-10" style="text-align: center;">
          <h2>Terms and Conditions </h2>
      </div>
      <div style="font-family: Avenir,Helvetica,Arial,sans-serif; font-size: 1rem;font-weight: 400;line-height: 1.5;">
          <p>LeeLee Teacher Edition and LeeLee Student Edition LICENSE AND TERMS OF USE</p>
          <p>Last Updated: December 2020</p>
          <p>
            These Terms of Use (&ldquo;Terms&rdquo;) govern your use of LeeLee Teacher Edition and LeeLee Student Edition (&ldquo;LeeLee&rdquo;)services, software, and content (collectively, &ldquo;Services&rdquo;), including the (i) the website www.claseleelee.com, (ii) our mobile iOS apps, and (iii) our mobile Android apps (iv) third-party providers that offer LeeLee Services directly or included with their product or service offerings.<br>
            By accessing or using these Services you agree to the Terms contained in this agreement (&ldquo;Agreement&rdquo;) as well as our Privacy Policy available at www.claseleelee.com. If you do not agree to these Terms and our Privacy Policy, you may not access or use the Services.<br>
            DO NOT PURCHASE A SUBSCRIPTION, REGISTER FOR AN ACCOUNT, OR USE THE SERVICES IF YOU ARE NOT IN AGREEMENT WITH THESE TERMS.<br>
            <span class="terms">1. LICENSE AND OWNERSHIP</span>
            All of the Services, visual interfaces, graphics, design, compilation, information, computer code (including source code or object code) are property of Escuela de Lectura LLC and protected by copyrights, trademarks, trade secrets, trade dress, or other proprietary rights.<br>
            Subject to the Terms in this agreement, you are granted a limited non-exclusive, non-transferrable license to use Services (a &ldquo;License&rdquo;) for educational non-commercial purposes during the term of your paid or trial period.<br>
            You may reproduce your personally generated content as images and videos for non-commercial educational purposes in materials, on websites, in presentations, and on social media sites, provided you cite the content was made with LeeLee.<br>
            You may NOT do any of the following:<br>
            &bull; modify, reverse-engineer, decompile, disassemble, download (other than temporary loading while accessing the Services), reproduce, copy, or resell the Services or any portion or derivative thereof;<br>
            &bull; commercially sell or provide access to the Services or any portion derivative thereof;<br>
            &bull; enable high volume, automated, electronic processes that apply to the Services or its systems; use any robot, spider, data miner, scraper or other automated means to access the Services or its systems for any purpose;<br>
            &bull; interfere or attempt to interfere with the proper working of the Services;<br>
            &bull; bypass or interfere with any of our security measures or features used to prevent or restrict access to any portion of the Services;<br>
            &bull; frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Services or the Materials;<br>
            &bull; use the Services for any purpose other than their intended purpose as educational tools for the personal benefit of end users or for the limited purpose of evaluating whether you wish to acquire a license in the Services;<br>
            &bull; allow any person access to the Services other than users authorized to use the Services; or<br>
            &bull; use LeeLee&rsquo;s name, trademarks, services marks or logos in any meta tags or any other &ldquo;hidden text&rdquo; or compile, repackage, disseminate or otherwise use data extracted from the Site.<br>
            This license extends to staff and enrolled students at the school up to the number of teacher and student licenses your school has purchased. The individuals authorized to use the Services under this license are referred to as &quot;Authorized Users.&quot; Authorized Users may use the Services at any time during the term of the paid or granted trial period, at school or at home.<br>
            
            <span class="terms">2. PAID LICENSES</span>

            All paid Licenses must be prepaid. You may cancel your order for the purchase of a License within seven (7) days of the date of purchase by contacting us by e-mail at sales@claseleelee.com. No refunds will be made after the expiration of this seven-day period.<br>
            It is our policy to sell Licenses only to individuals age 18 and older. When purchasing a License, you warrant that you are 18 or older.<br>
            We will not automatically renew your License after the expiration of a License term. If you wish to renew your License, you will need to place a new order for a new term.<br>
            If you are purchasing a School or District License, you warrant that you have the authority to bind your school and/or school district in making the purchase and the terms of this Agreement. To the extent permitted by law, your school agrees to waive any defense of sovereign immunity and agrees that the purchase of the License and the use of the Services is a proprietary function.
            Family Accounts. A Family Account can have a maximum of four users, including an Adult User and up to three Child Users that Adult Users add to the Family Account. They are accounts registered through the app.<br>
            <span class="terms">3. Use and Fees of Family Accounts.</span>
            Every Account has one designated Adult User who must be at least 18 years of age. You are responsible for maintaining the confidentiality of your password and Account information. You agree (a) that you will provide complete and accurate registration information about yourself and any individual you authorize to access your Account and keep your Account information up-to-date, (b) that you are solely responsible for all activities that occur under your Account, (c) to notify us immediately of any unauthorized Account use, (d) that we are in no way responsible for any loss that you may incur as a result of any unauthorized use of your Account and password, and (e) that you will not sell, transfer, or assign your Account or any Account rights. If we learn that an ineligible user has created an Account, we may seek confirmation of the user’s status or deactivate the Account.<br>
            &bull; (i). You must either use a credit card, debit card or other payment mechanism accepted by us (e.g., Google Play Store, Apple’s iTunes App Store) (“Pay Mechanism”) to activate and maintain a paid Account. You authorize us to charge you through the Pay Mechanism that you use when registering for an Account. You will also be responsible for charges for any products or services that you order that are offered for sale through the Services including any specific functionalities of our products and/or services.Should the Pay Mechanism provided initially be declined for insufficient funds or any other reason, we reserve the right to attempt to recharge the Pay Mechanism in full or in lesser installments of the initially incurred charge. You will not be charged more than the amount for which you purchased your Account subscription.<br>
            &bull; (ii) Subscription Renewal. By activating an Account, you agree that we may renew your subscription automatically for the same subscription term on the day after your previous subscription ends, and you authorize us to charge you for the subscription term, unless you cancel your Account prior to its renewal date through the procedures described in the “Subscription Cancellation” section below. We will charge your credit card (or the other method of payment initially used by you) each year, month, or other applicable period (depending on the term that you selected) for the then-current applicable price.<br>
            &bull; (iii) Subscription Cancellation. You must follow the cancellation instructions provided in the iTunes App Store or Google Play. For all Accounts, you will continue to have access to your Account for the period of time that has already been prepaid. After you cancel your Account, we will not charge you any subscription fees after the expiration of your then-current subscription. Please note we do not provide full or partial refunds for prepaid sums. In any event, you will be able to continue to use the Services throughout the remainder of the subscription period for which you have already paid.<br>
            &bull; (iv) Refunds. If you sign up for a trial subscription through a Pay Mechanism that is NOT through Apple’s iTunes App Store, you are entitled to a refund for any fees paid for your Account within 7 days of the payment date. After the 7th day, you are not entitled to any refund. If your Account includes any additional add on services, you are not entitled to any refund.<br>

            <span class="terms">4. RESPONSIBILITIES AND SUPERVISION OF USERS</span>
            Schools and Authorized Users are responsible for keeping login details confidential and secure and will not share or permit use of their login details with anyone who is not an Authorized User. Schools and individuals are also responsible for all actions and omissions of their Authorized Users. If you suspect any login details associate with your License are being used by anyone who is not an Authorized User, you will promptly change those login details or notify the person responsible for managing those login details.<br>
            If your account includes an email address, you consent to receive electronic communications from us to that e-mail address. You also agree that any communication via e-mail satisfies any legal requirement that such communications be made in writing. We also may provide such communications on the Site. You acknowledge that it is important to keep the e-mail address associated with your account current because although you may be able to log into your account using an old e-mail address, you will not receive messages about your account or important updates to the Services, nor will you be able to perform a password recovery.<br>
            If we believe that any use occurring under your License, regardless of whether you have knowledge of such use, violates this Agreement or any applicable law, or is harmful to us, the Service, or any other User, we reserve the right to do one or more of the following, at our sole discretion and without notice:<br>
            &bull; issue a warning to you;<br>
            &bull; temporarily or indefinitely suspend any logins or your License entirely;<br>
            &bull; terminate any user's right to use or access the Services, or any user&rsquo;s account; or<br>
            &bull; terminate your License.<br>
            If we take any of these actions against you due to such violations, you will not be entitled to any refund of the purchase price for your License.</p>

            <p>
            <span class="terms">5. AVAILABILITY OF THE SERVICES</span>

            We use reputable technology providers and partners to try to minimize the amount of time that the Services are unavailable to Users. We cannot and do not, however, guarantee that the Services will be accessible at all times.<br>
            We may need to perform occasional maintenance or other work that will render the Services inaccessible. We will use reasonable efforts to notify you prior to such maintenance by placing notice(s) on our website, via e-mail, or on social media, and to perform such maintenance outside of normal school hours, provided that we will not have any liability if we fail to do so.<br>
            <span class="terms">6. CHANGES TO THESE TERMS AND SERVICES<br></span>
            We reserve the right to make changes to posted policies and these Terms at any time. It is our policy to post a notice on the site alerting users of a change, but we will not be responsible if for any reason we fail to do so. Your use of the Site or the Materials after a change in these Terms will constitute your acceptance of the revised Terms. We also reserve the right to modify the Services and the Materials from time to time without notice.<br>
            <span class="terms">7. DISCLAIMERS AND LIMITATION OF LIABILITY<br></span>
            WE PROVIDE THE SERVICES AND THE MATERIALS ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, WE DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE AND NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE.<br>
            WITHOUT LIMITING THE FOREGOING, WE MAKE NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED: (I) AS TO THE OPERATION OF THE SERVICES, OR THE INFORMATION, CONTENT, OR MATERIALS INCLUDED THEREON; (II) THAT THE SERVICES OR YOUR ACCESS TO THE MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE; (III) AS TO THE ACCURACY, RELIABILITY, OR CURRENCY OF ANY INFORMATION, CONTENT, OR MATERIALS PROVIDED THROUGH THE SERVICES; OR (IV) THAT THE SERVICES, ITS SERVERS, THE MATERIALS, OR E-MAILS SENT FROM OR ON BEHALF OF US OR THE SERVICES ARE FREE OF VIRUSES, SCRIPTS, TROJAN HORSES, WORMS OR OTHER HARMFUL COMPONENTS.<br>
            WE DO NOT GUARANTEE OR WARRANT ANY EDUCATIONAL RESULTS AND NO STATEMENTS OR DECLARATIONS FROM REPRESENTATIVES OF Escuela de Lectura LLC, WHETHER WRITTEN OR ORAL, SHOULD BE RELIED UPON AS GUARANTEES OR WARRANTIES OF EDUCATIONAL RESULTS OR IMPROVEMENTS.<br>
            WE CANNOT AND DO NOT GUARANTEE THE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE WEBSITE, APPS, OR ANY RELATED SERVICES. THE OPERATION OF THE SERVICES MAY BE INTERFERED WITH BY NUMEROUS FACTORS OUTSIDE OF OUR CONTROL.<br>
            IN NO EVENT WILL WE BE LIABLE FOR LOST DATA.<br>
            UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY DAMAGES THAT RESULT FROM THE USE OF OR INABILITY TO USE THE SERVICES, INCLUDING BUT NOT LIMITED TO RELIANCE BY A USER ON ANY INFORMATION OBTAINED FROM THE SERVICES OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION, OR UNAUTHORIZED ACCESS TO OUR RECORDS, PROGRAMS, OR SERVICES. USER HEREBY ACKNOWLEDGES THAT THIS PARAGRAPH SHALL APPLY TO ALL INFORMATION, CONTENT, AND MATERIAL AVAILABLE THROUGH OR DESCRIBED ON THE SITE.<br>
            WE WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING FROM THE USE OF THE SERVICES OR THE MATERIALS OR THE PURCHASE OF ANY LICENSE, EVEN IF YOU HAVE ADVISED US OF THE POSSIBILITY OF SUCH DAMAGES.<br>
            YOU AGREE THAT THE MAXIMUM LIABILITY THAT WE MAY HAVE UNDER THESE TERMS OR RELATING TO THE USE OF THE SERVICES OR THE MATERIALS BY YOU OR YOUR AUTHORIZED USERS IS A REFUND OF THE FEES YOU PAID TO Escuela de Lectura LLC IN THE PRIOR TWELVE MONTHS.<br>
            CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY, AND YOU MAY HAVE ADDITIONAL RIGHTS.<br>
            <span class="terms"> 8. APPLICABLE LAW AND JURISDICTION<br></span>
            The Site and the Materials are created and controlled by us in the State of Texas. The laws of the State of Texas will govern these Terms, without giving effect to any principles of conflicts of laws.<br>
            </p>
      </div>
    </v-container>
</template>

<script>
export default {
    mounted(){
        location.hash = "#top";
    }
}
</script>

<style scoped>
    .terms{
        padding: 10px 0 15px 0 !important;
        display: block;
        font-size: 19px;
    }

</style>